import "./BonusModel.scss";

function BonusModal({ header, body1, body2, body3, handleContinue }) {
  return (
    <div className="modal-overlay">
    <div className="modal-bonus">
      <div className="modal-content-bonus">
        {/* Header */}
        <h3 className="modal-header">{header}</h3>

        {/* Body paragraphs */}
        <div className="modal-body">
          <p>{body1}</p>
          <p>{body2}</p>
          <p>{body3}</p>
        </div>

        {/* Button container */}
        <div className="modal-buttons">
          <button className="continue-button" onClick={handleContinue}>
            <div className="text">OK</div>
          </button>
        </div>
      </div>
    </div>
  </div>
  );
}

export default BonusModal;
